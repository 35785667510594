<template>
  <div class="d-flex">
    <div
      class="advanced-settings-target pos-relative mx-1 mt-1 h-26"
      :class="[
        isFieldOpen && 'advanced-settings-target-active',
        isDarkMode && 'darkmode-advanced-settings-target',
      ]"
      @click.stop="fieldSpanClicked"
    >
      <!--TITLE THAT LOOKS LIKE INPUT -->
      <span
        
        class="advanced-title ml-1"
        style="width: 200px"
        >{{ nthField }} Field
        <i
          :class="[
            'v-icon notranslate mdi mdi-menu-down',
            isFieldOpen && 'primary--text rotate',
          ]"
        ></i>
      </span>

      <!-- <transition tag="div" name="hierarchy"> -->
        <!--WRAPPER FOR THE DROPDOWN-->
        <div
          v-if="isFieldOpen"
          @click.stop
          class="wrapper-2 px-2 pb-2 inputs-wrapper"
        >
          <div class="my-2 d-flex justify-end">
            <v-icon @click.stop="$emit('set-is-field-open', false)" class="close-button pointer"
              >mdi-close-circle-outline
            </v-icon>
          </div>
          <!-- <span class="mt-1">Label of the text field:</span> -->
          <v-text-field
            v-model="interfaceDynamicField.textToDisplay"
            label="Label of the input"
            height="18px"
            class="mt-4 pa-0 mx-2"
            style="margin-top: 3px !important"
            maxlength="50"
            hide-details
          ></v-text-field>
          <v-checkbox
            v-model="interfaceDynamicField.isHierarchy"
            label="Value of this field will be part of hierarchy in recipient's folders tree"
            dense
            hide-details
            class="mt-8 pa-0"
            style="min-width: 140px"
            height="24px"
          ></v-checkbox>
          <v-checkbox
            v-model="interfaceDynamicField.required"
            label="Value of this field will be required"
            dense
            hide-details
            class="mt-8 pa-0"
            style="min-width: 140px"
            height="24px"
          ></v-checkbox>
          <v-select
            :items="enumItems"
            v-model="interfaceDynamicField.validation"
            label="Select validation"
            item-text="text"
            item-value="value"
            class="mt-4 w-145"
          ></v-select>
        </div>
      <!-- </transition> -->
    </div>
    <div>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-hover v-slot="{ hover }">
                <v-icon
                  :color="hover ? 'red' : 'grey'"
                  v-on="on"
                  class="my-2"
                  @click.stop="deleteUserClicked"
                  >mdi-delete</v-icon
                >
              </v-hover>
            </template>
            <span>Delete Field</span>
          </v-tooltip>
        </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ManageDynamicFields',

  data() {
    return {
      enumItems: [
        { text: 'None', value: 0 },
        { text: 'Email', value: 1 },
        { text: 'Phone Number', value: 2 },
        { text: 'Israel ID', value: 3 },
      ],
    }
  },
  emits: ['delete-field', 'set-is-field-open', 'close-other-fields'],
  props: {
    interfaceDynamicField: Object,
    fieldIndex: Number,
    isFieldOpen: Boolean,
  },
  computed: {
    ...mapGetters(['isDarkMode']),
    nthField() {
      switch (this.fieldIndex) {
        case 0:
          return 'First'
        case 1:
          return 'Second'
        case 2:
          return 'Third'
      }
    },
  },
  methods: {
    deleteUserClicked() {
      this.$emit('delete-field')
      this.$emit('set-is-field-open', false)
    },
    fieldSpanClicked(){
        this.$emit('set-is-field-open', !this.isFieldOpen)
        this.$emit('close-other-fields', this.fieldIndex)
    },
  },
}
</script>

<style lang="scss" scoped>
.rotate {
  transform: rotate(180deg);
}

.advanced-settings-target {
  transition: box-shadow 0.25s ease;
  box-shadow: #a1a1a1 0px 1px;
  &:hover {
    box-shadow: #000 0px 1px;
  }
}

.advanced-settings-target-active {
  transition: box-shadow 0.25s ease;
  box-shadow: #2289dd 0px 2px;
  &:hover {
    box-shadow: #2289dd 0px 2px;
  }
}

.advanced-settings-target-active-error {
  transition: box-shadow 0.25s ease;
  box-shadow: #f44336 0px 2px;
  &:hover {
    box-shadow: #000 0px 1px;
  }
}

.darkmode-advanced-settings-target {
  &:hover {
    box-shadow: #fff 0px 1px;
  }
}

.advanced-title {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  transition: 0.3s ease;
  &:active {
    color: rgba(0, 0, 0, 1);
  }
}

.inputs-wrapper {
  position: absolute;
  background: #fff;
  z-index: 10;
  width: 445px;
  top: 30px;
  transition: all 0.3s ease;
  overflow: auto;
}
</style>
