<template>
  <div
    @dragenter.prevent="isDrag = true"
    @dragleave.prevent="isDrag = false"
    @dragover.prevent="isDrag = true"
    @drop.prevent="fileDropped"
    class="fill-height fill-width d-flex"
  >
    <v-tab-item
      value="uploading"
      class="fill-width fill-height d-flex upload-data"
    >
      <!--LEFT SIDE-->
      <div class="ma-2">
        <!--UPLOADING DETAILS-->
        <UploadingDetails
          :session="session"
          :userActiveSourceRoutes="userActiveSourceRoutes"
          :filteringErrMessage="filteringErrMessage"
          :startFilteringClicked="startFilteringClicked"
          :restartSessionClicked="restartSessionClicked"
          :lightSessionRestart="lightSessionRestart"
          :activeTab="activeTab"
        />

        <!--PROGRESS-->
        <UploadProgress
          v-if="isShowProgressBar"
          :filteringProgress="filteringProgress"
          :timer="timer"
          :session="session"
          type="desktop"
        />
      </div>

      <!--RIGHT SIDE-->
      <div
        class="upload-right-side-wrapper d-flex align-center justify-center ma-2"
      >
        <!--DROP FILES-->
        <DropFiles
          v-if="levelOfUploading === 'drop'"
          :session="session"
          :isDrag="isDrag"
          type="desktop"
        />

        <!--TABLE AND FILES-->
        <div
          v-if="levelOfUploading === 'table'"
          class="d-flex flex-column fill-height fill-width"
        >
          <!--TABLE INFO / SESSION INFO-->
          <SessionInfo
            :session="session"
            :curFileUploaded="curFileUploaded"
            :sessionFiles="sessionFiles"
            :sessionSize="sessionSize"
            :cancelSessionClicked="cancelSessionClicked"
          />

          <!--FILES TABLE-->
          <UploadFilesTable
            :session="session"
            :curFileUploaded="curFileUploaded"
            :isDrag="isDrag"
          />
        </div>

        <!--ENDING REPORT-->
        <UploadReportDialog
          v-if="levelOfUploading === 'report'"
          :restartSessionClicked="restartSessionClicked"
          :statusResults="statusResults"
        />
      </div>
    </v-tab-item>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { api } from '@/config'

//Childrens
import UploadingDetails from './Children/UploadingDetails/UploadingDetails.vue'
import SessionInfo from './Children/SessionInfo/SessionInfo.vue'

//Base components
import Report from '@/components/WebsiteInterface/CommonComponents/Report/Report.vue'
import DropFiles from '@/components/WebsiteInterface/CommonComponents/DropFiles/DropFiles.vue'
import UploadFilesTable from '@/components/WebsiteInterface/CommonComponents/UploadFilesTable/UploadFilesTable.vue'
import UploadProgress from '@/components/WebsiteInterface/CommonComponents/UploadProgress/UploadProgress.vue'

//Website interface data
import {
  checkUploadingDetails,
  startObjForUpload,
} from '@/components/WebsiteInterface/Websiteinterface'

//Uploading functions
import {
  cancelUploading,
  deleteIndividualFiles,
  startFiltering,
  addDroppedFileOrDirectory,
} from '@/components/WebsiteInterface/uploadingFunctions'
import UploadReportDialog from '@/components/WebsiteInterface/CommonComponents/UploadReportDialog/UploadReportDialog.vue'

export default {
  name: 'UploadDesktop',
  data() {
    return {
      ...JSON.parse(JSON.stringify(startObjForUpload)),
    }
  },
  components: {
    UploadingDetails,
    DropFiles,
    UploadFilesTable,
    UploadProgress,
    Report,
    SessionInfo,
    UploadReportDialog,
  },
  props: {
    userActiveSourceRoutes: Array,
  },
  watch: {
    session: {
      handler: function (n) {
        checkUploadingDetails(this, 'desktop')
      },
      deep: true,
      immediate: true,
    },
    language() {
      checkUploadingDetails(this, 'desktop')
    },

    'activeTab.tab': {
      handler: function (n) {
        checkUploadingDetails(this, 'desktop')
        this.session.subject = ''
        this.session.message = ''
        this.session.selectedCasualUsers = []
        this.session.selectedUsers = []
        if (this.userActiveSourceRoutes.length > 1)
          this.session.selectedRoute = ''
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters([
      'isInMiddleOfFiltering',
      'loggedUser',
      'language',
      'interactiveInterfaceFields',
    ]),
    levelOfUploading() {
      //if not filtering AND there are no files AND there is no report then show drop files
      if (
        !this.isInMiddleOfFiltering &&
        !this.session.filesData.length &&
        !this.statusResults.session.operationId
      )
        return 'drop'

      //if there are files and in middle of filtering
      if (this.session.filesData.length) return 'table'

      //if there is session report
      if (this.statusResults.session.operationId) return 'report'
    },
    isShowDropFiles() {
      if (this.session.filesData.length) return false
      else if (this.isInMiddleOfFiltering) return false
      // else if()
      return true
    },
    isShowProgressBar() {
      if (this.isInMiddleOfFiltering) return true
      return false
    },
  },
  methods: {
    ...mapActions(['GET_LOGGED_USER_ROUTES']),
    fileDropped(e) {
      if (this.isInMiddleOfFiltering) return
      addDroppedFileOrDirectory(this, e)
    },

    async startFilteringClicked() {
      let biggestFileSize = 0
      this.session.filesData.forEach(el =>
        el.file.numbSize > biggestFileSize
          ? (biggestFileSize = el.file.numbSize)
          : null
      )

      const isSessionApprovedRes = await api.get(
        `filter/sizepermissions?total=${this.session.totalSize}&biggest=${biggestFileSize}`
      )
      if (isSessionApprovedRes.status !== 200) return

      startFiltering(this, 'desktop')
    },

    async restartSessionClicked() {
      //if the user is individual and the session wansnt restarted
      if (
        this.loggedUser.role === 'Individual' &&
        !this.session.filesData.length
      )
        await deleteIndividualFiles(
          this.session.operationId,
          this.loggedUser.token
        )

      //this will restart the session
      Object.keys(startObjForUpload).forEach(key => {
        this[key] = JSON.parse(JSON.stringify(startObjForUpload))[key]
      })
    },

    lightSessionRestart() {
      //this will run when a user did a session and
      //then changed the route, casuals, subject etc...
      if (!this.session.operationId) return

      this.session.htmlReport = {
        summary: '',
        date: '',
      }
      this.session.operationId = ''

      this.statusResults = {
        intact: 0,
        modified: 0,
        rejected: 0,
        total: 0,
        session: {},
        partial: 0,
      }
    },

    cancelSessionClicked() {
      cancelUploading(this)
    },
  },
  async mounted() {
    // this.session.interfaceDynamicFields = this.interactiveInterfaceFields
    await this.GET_LOGGED_USER_ROUTES()
    if (this.loggedUser.role != 'Individual' && !this.userActiveSourceRoutes.length) this.activeTab.tab = 'casual'
  },
}
</script>

<style scoped src="./UploadDesktop.css"></style>
