<template>
  <div>
    <v-text-field
      v-for="(item, i) of session.interfaceDynamicFields"
      v-model="session.interfaceDynamicFields[i].value"
      @input="lightSessionRestart"
      :rules="dynamicFieldsRules[i]"
      :disabled="isInMiddleOfFiltering"
      :label="session.interfaceDynamicFields[i].textToDisplay"
      outlined
      dense
      maxlength="40"
      counter
    >
      <template
        v-slot:append
        v-if="session.interfaceDynamicFields[i].isHierarchy"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-information-outline</v-icon>
          </template>
          <span v-html="forbiddenChars + ' ' + $t('are forbidden')"> </span>
        </v-tooltip>
      </template>

      <template v-slot:counter="{ props }">
        <span :class="[props.value >= 35 && 'red--text']">
          {{ ` ${props.value} / ${props.max}` }}</span
        >
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { isFolderNameValid } from '@/utils'
import { mapGetters } from 'vuex'
import { validateEmail, validateIsraelId, validatePhoneNumber } from '@/utils'

export default {
  name: 'DynamicFields',

  data() {
    return {
      isSubjectError: false,
      forbiddenChars: '> , < , : , " , | , ? , *',
    }
  },

  props: {
    session: Object,
    lightSessionRestart: Function,
  },

  computed: {
    ...mapGetters([
      'loggedUser',
      'isInMiddleOfFiltering',
      'interactiveInterfaceFields',
    ]),
    dynamicFieldsRules() {
      const fieldsRules = []
      for (let i = 0; i < this.session.interfaceDynamicFields.length; i++) {
        console.log(i)
        fieldsRules[i] = []
        fieldsRules[i].unshift(v => {
          if (
            this.session.interfaceDynamicFields[i].required &&
            !this.session.interfaceDynamicFields[i].value
          ) {
            return `${
              this.session.interfaceDynamicFields[i].textToDisplay
            } ${this.$t('is required')}`
          } else if (this.session.interfaceDynamicFields[i].validation) {
            switch (this.session.interfaceDynamicFields[i].validation) {
              case 1: // email
                if (v && !validateEmail(v.trim())) return 'Email is not valid'
                break
              case 2: // phone number
                if (v && !validatePhoneNumber(v.trim()))
                  return this.$t('Mobile is not valid')
                break
              case 3: // israel id
                if (validateIsraelId(v && v.trim())) return 'Invalid ID'
                break
            }
          }
          return true
        })
      }
      console.log(fieldsRules)
      return fieldsRules
    },
    // subjectRules() {
    //   const rules = [
    //     v => {
    //       if (isFolderNameValid(v)) return true
    //       else {
    //         if (this.interactiveInterfaceFields.subjectFieldText === 'Subject')
    //           return this.$t('Subject is invalid')
    //         else return this.$t('Request number is invalid')
    //       }
    //     },
    //   ]

    //   //if the user is casual add the subject is required
    //   if (this.loggedUser.role === 'Casual')
    //     rules.unshift(v => {
    //       if (this.session.subject) return true
    //       else {
    //         if (this.interactiveInterfaceFields.subjectFieldText === 'Subject')
    //           return this.$t('Subject is required')
    //         else return this.$t('Request number is required')
    //       }
    //     })
    //   return rules
    // },
  },
}
</script>
