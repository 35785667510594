import { render, staticRenderFns } from "./UploadDesktop.vue?vue&type=template&id=cc1ae32a&scoped=true&"
import script from "./UploadDesktop.vue?vue&type=script&lang=js&"
export * from "./UploadDesktop.vue?vue&type=script&lang=js&"
import style0 from "./UploadDesktop.css?vue&type=style&index=0&id=cc1ae32a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc1ae32a",
  null
  
)

export default component.exports